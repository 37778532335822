<template>
  <a-modal
    title="物业账单"
    :width="1080"
    :visible="visible"
    :confirmLoading="loading"
    @ok="handelsubmit"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-row :gutter="[16,16]">
          <a-col :span="12">
            <a-form-item label="付款日期">
              <a-date-picker
                v-decorator="['payment_date', {rules: [{required: true, message: '必填项，请填写信息'}]}]"
                format="YYYY-MM-DD"
                show-time
                :disabled-date="disabledStartDate"
                placeholder="付款日期"
              />
            </a-form-item>
            <a-form-item label="账单开始日期">
              <a-date-picker
                v-decorator="['start_date', {rules: [{required: true, message: '必填项，请填写信息'}]}]"
                :disabled-date="disabledStartDate"
                show-time
                format="YYYY-MM-DD"
                placeholder="账单开始日期"
                @openChange="handleStartOpenChange"
              />
            </a-form-item>
            <a-form-item label="账单结束日期">
              <a-date-picker
                v-decorator="['end_date', {rules: [{required: true, message: '必填项，请填写信息'}]}]"
                :disabled-date="disabledEndDate"
                show-time
                format="YYYY-MM-DD"
                placeholder="账单结束日期"
                :open="endOpen"
                @openChange="handleEndOpenChange"
              />
            </a-form-item>
            <a-form-item label="总额">
              <a-input-number v-decorator="['total_payment']"/><span> 元</span>
            </a-form-item>
            <a-form-item label="备注">
              <a-textarea :autoSize="{minRows:2,maxRows:6}" placeholder="备注" v-decorator="['remark']" allow-clear />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>
</template>
<script>
import pick from 'lodash.pick'
import { contract_account, contract_list } from '@/api/contract'
import { corporation_list } from '@/api/corporation'
import moment from 'moment'
// 表单字段
const fields = ['id', 'create_time', 'last_update', 'username', 'password', 'nickname',
  'avatar', 'role', 'email', 'cellphone', 'location', 'is_active']
export default {
  components: {},
  props: {
    visible: {
      type: Boolean,
      required: false
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    return {
      pay_time_default: moment('00:00:00', 'HH:mm:ss'),
      endOpen: false,
      color: '#3f3f3f',
      form: this.$form.createForm(this),
      dictionaries: this.$Dictionaries,
      contractList: [],
      zujinList: [],
      tip: '',
      dataSource: [],
      content: ''
    }
  },
  created () {
    console.log('custom modal created', this.form)
    this.getcontract()
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))
    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.model && this.form.setFieldsValue(pick(this.model, fields))
    })
  },
  mounted () {
    this.getzujincontract()
  },
  methods: {
    handelsubmit () {
      if (!this.tip) {
        this.$emit('ok')
      } else {
        this.$message.warning(this.tip)
      }
    },
    disabledStartDate (startValue) {
      const endValue = this.form.getFieldValue('end_date')
      if (!startValue || !endValue) {
        return false
      }
      return startValue.valueOf() > endValue.valueOf()
    },
    disabledEndDate (endValue) {
      const startValue = this.form.getFieldValue('start_date')
      console.log(startValue.valueOf())
      if (!endValue || !startValue) {
        return false
      }
      return startValue.valueOf() >= endValue.valueOf()
    },
    handleStartOpenChange (open) {
      if (!open) {
        this.endOpen = true
      }
    },
    handleEndOpenChange (open) {
      this.endOpen = open
    },
    displayRender ({ labels }) {
      return labels[labels.length - 1]
    },
    getcontract () {
      contract_list({})
        .then(({ data }) => {
          console.log(data, '我是数据列表@')
          this.contractList = data
        })
    },
    getzujincontract () {
      contract_account({})
        .then(({ data }) => {
          console.log(data, '我是数据列表@')
          this.zujinList = data
        })
    },
    onChange (value) {
      console.log(value)
    },
    onSearch (searchText) {
      corporation_list({ name: searchText }).then((res) => {
        const reslut = res.data.entries || []
        this.corList = reslut
        if (reslut.length === 0) {
          this.tip = '未找到您输入的企业'
        } else {
          this.tip = ''
        }
        console.log('我是企业列表', reslut)
        this.dataSource = !searchText ? [] : reslut.map((item) => item.name)
      })
    },
    onSelect (value) {
      console.log(value, '我是选择的值', this.corList)
      // eslint-disable-next-line eqeqeq
      const corporation_id = this.corList.find((item) => (item.name === value) && item).id
      this.form.setFieldsValue({
        corporation_id: corporation_id
      })
      console.log('onSelect', value, this.corList.filter((item) => (item.name === value) && item))
    }
  }
}
</script>
