<template>
  <a-modal
    title="保证金账单"
    :width="680"
    :visible="visible"
    :confirmLoading="loading"
    @ok="handleSubmit"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-row :gutter="[16,16]">
          <a-col :span="24">
            <a-form-item label="日期" :label-col="formLayout.labelCol" :wrapper-col="formLayout.wrapperCol">
              <a-date-picker
                v-decorator="['create_date', {rules: [{required: true, message: '必填项，请填写信息'}]}]"
                format="YYYY-MM-DD"
                show-time
                placeholder="日期"
              />
            </a-form-item>
            <a-form-item label="金额" :label-col="formLayout.labelCol" :wrapper-col="formLayout.wrapperCol">
              <a-input-number style="width: 57%" v-decorator="['total_payment']"/><span> 元</span>
            </a-form-item>
            <a-form-item label="备注" :label-col="formLayout.labelCol" :wrapper-col="formLayout.wrapperCol">
              <a-textarea :autosize="{minRows:2,maxRows:6}" placeholder="备注" v-decorator="['remark']" allow-clear />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>
</template>
<script>
// 表单字段
export default {
  components: {},
  props: {
    visible: {
      type: Boolean,
      required: false
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 11 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    return {
      form: this.$form.createForm(this),
      formLayout: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 7 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 13 }
        }
      }
    }
  },
  methods: {
    handleSubmit () {
      if (!this.tip) {
        this.$emit('ok')
      } else {
        this.$message.warning(this.tip)
      }
    }
  }
}
</script>
