<template>
  <a-modal
    title="预览"
    :width="1080"
    :visible="visible"
    okText="打印"
    :confirmLoading="loading"
    @ok="handleOk"
    @cancel="() => { $emit('cancel') }"
  >
    <div class="printwrap">
      <div v-html="content" id="printMe"></div>
    </div>
  </a-modal>
</template>
<script>
// 表单字段
export default {
  components: {},
  props: {
    visible: {
      type: Boolean,
      required: false
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    content: {
      type: String,
      default: ''
    }
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    return {}
  },
  created () {
    console.log(typeof this.content)
  },
  methods: {
    handleOk () {
      this.$htmlToPaper('printMe')
      this.$emit('ok')
    }
  }
}
</script>
<style>
  .printwrap{
    height: 500px;
    overflow-y: scroll;
  }
</style>
