<template>
  <a-modal
    title="操作"
    :width="360"
    :visible="visible"
    :confirmLoading="loading"
    @ok="handelSubmit"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form">
        <a-row :gutter="[16,16]">
          <a-col :span="24">
            <a-form-item
              label="日期"
              :label-col="formLayout.labelCol"
              :wrapper-col="formLayout.wrapperCol">
              <a-date-picker
                v-decorator="['terminal_date', {rules: [{required: true, message: '必填项，请填写信息'}]}]"
                format="YYYY-MM-DD"
                placeholder="日期"
                @change="onChange"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>
</template>
<script>

export default {
  components: {},
  props: {
    visible: {
      type: Boolean,
      required: false
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    return {
      form: this.$form.createForm(this),
      terminal_date: ''
    }
  },
  methods: {
    handelSubmit () {
      this.$emit('ok', { terminal_date: this.terminal_date })
    },
    onChange (date, dateString) {
      console.log(date, dateString)
      this.terminal_date = dateString
    }
  }
}
</script>
